import React from "react";
import CAULogo from "../../assets/img/logo-cau.png";
import BAMLogo from "../../assets/img/logo-bam.png";
import EucleiaLogo from "../../assets/img/logo-eucleia.png";
import WellifyLogo from "../../assets/img/logo-wellify.png";
import ReadMore from "../../components/readMore/readMore.js";

import "./style.scss";

const RotatingIcons = ({ title, body, to, twoIcons, threeIcons }) => {
  return (
    <div className="rotatingIcons">
      <div className="container">
        {twoIcons && (
          <div className="columns">
            <div className="column is-7">
              <div id="greenWaveB1" />
              <div id="greenWaveB2" />
              <div className="logoContainerB">
                <img src={EucleiaLogo} id="floatIconB1" alt="Logo Eucleia" />
                <img src={WellifyLogo} id="floatIconB2" alt="Logo Wellify" />
              </div>
            </div>
            <div className="column is-5">
              <h2 className="pink">{title}</h2>
              <br/>
              <p>{body}</p>
              <div className="section" />
              <ReadMore to={to} text="Scopri di più" color="white" />
            </div>
          </div>
        )}
        {threeIcons && (
          <div className="columns">
            <div className="column is-7">
              <h2 className="pink">{title}</h2>
              <br/>
              <p>{body}</p>
              <div className="section" />
              <ReadMore to={to} text="Scopri di più" color="white" />
            </div>
            <div className="column is-5">
              <div id="greenWaveA1" />
              <div id="greenWaveA2" />
              <div className="logoContainerA">
                <img src={CAULogo} id="floatIconA1" alt="Logo Connect@You" />
                <img src={BAMLogo} id="floatIconA2" alt="Logo Book@Me" />
                <img src={EucleiaLogo} id="floatIconA3" alt="Logo Eucleia" />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default RotatingIcons;
