import React, { useContext } from "react";
import { ThemeContext } from "../context/ThemeContext";
import Header from "../components/header/header.js";
import Image from "../components/image/image.js";
import RotatingIcons from "../components/rotatingIcons/rotatingIcons.js";
import FourArrows from "../components/fourArrows/fourArrowsIcon.js";

import "../assets/styles/pages/_our-integrations.scss";

const PageProps = {
  title: "Le nostre integrazioni",
  metaDescription: "",
};

const OurIntegration = () => {
  const theme = useContext(ThemeContext);

  return (
    <>
      <Header {...PageProps} />
      <div className="ourIntegrations">
        <RotatingIcons
          threeIcons={true}
          title="La Suite Hospitality"
          to="/hospitality"
          body={
            <>
              Diviene un potente strumento per gestire ed accrescere la brand
              reputation e ti permette di seguire il cliente in ogni momento
              della permanenza presso tua struttura.
              <br />
              <br />
              Offrendogli la possibilità di effettuare il checkIN online,
              garantendogli una navigazione sicura, e un’assistenza durante il
              soggiorno; sarai in grado di monitorare l’umore del tuo ospite e
              di far fronte alle sue esigenze.
              <br />
              <br />
              Le recensioni positive saranno assicurate, insieme ad un aumento
              della brand reputation!
            </>
          }
        />
        <div className="section" />
        <RotatingIcons
          twoIcons={true}
          title="La Suite Wellness"
          to="/wellness"
          body={
            <>
              La nostra Suite Wellness ti consentirà di aumentare la forza del
              tuo brand online, grazie ad un aumento esponenziale delle
              recensioni positive.
              <br />
              <br />
              Eucleia si integra perfettamente con Wellify, il nostro software
              gestionale in cloud dedicato al settore wellness, che si rivolge
              in particolare a centri estetici, saloni di parrucchieri, SPA e
              centri benessere.
            </>
          }
        />
        <div className="container">
          <div className="columns">
            <div className="column is-7">
              <h2>Integrazioni con Software di Terze Parti</h2>
              <br />
              <p>
                La suite Hospitality si completa con Isidoro Software, PMS per
                hôtellerie, abbracciando le esigenze di hotel, ristoranti e
                centri benessere.
                <br />
                È semplice e di facile utilizzo, migliora l’affidabilità e la
                gestione della tua struttura.
                <br />
                <br /> Le dashboard sono intuitive permettendo una pratica
                gestione di planning e prenotazioni da qualsiasi dispositivo.
                <br />
                <br />
                L’integrazione tra tutti i nostri software rende agevole la
                gestione dell’utente in ogni fase, dalla prenotazione al
                check-out.
                <br />
                <br /> Grazie all'integrazione con <b>MailUp</b> tutti i dati profilati,
                raccolti tramite Connect@You potranno essere gestiti
                direttamente dalle liste presenti all'interno del software <b>MailUp</b>,
                semplificando la gestione per l'invio delle tue campagne
                marketing.
              </p>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="section" />
          <div className="columns">
            <div className="column ">
              <Image filename="isidoro.png" alt="" />
            </div>
            <div className="column ">
              <div className="arrow">
                <FourArrows direction={theme.isMobile ? "Down" : "Right"} />
              </div>
            </div>
            <div className="column">
              <Image className="floatN" filename="puzzle.png" alt="" />
            </div>
            <div className="column ">
              <div className="arrow">
                <FourArrows direction={theme.isMobile ? "Up" : "Left"} />
              </div>
            </div>
            <div className="column">
              <Image filename="mailup.png" alt="" />
            </div>
          </div>
        </div>
        <div className="section" />
      </div>
    </>
  );
};

export default OurIntegration;
